.slick-list {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.slick-slide.slick-center {
  transform: scale(1.1) !important;
  opacity: 1 !important;
  transition: all 1s ease !important;
}

.slick-slide {
  opacity: 0.3 !important;
  transition: all 1s ease !important;

  @media (max-width: 480px) {
    opacity: 1 !important;
  }
}

.slick-dots {
  position: absolute !important;
  bottom: 0%;
  right: 0%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots li {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots li.slick-active button {
  background-color: #0066ff !important;
}

.slick-dots li button {
  background-color: #00255c !important;
  border-radius: 50% !important;
  /* the circle size */
  width: 12px !important;
  height: 12px !important;
}

.slick-dots li button:before {
  font-size: 0px !important;
  width: 8px !important;
  height: 8px !important;
  color: #0066ff !important;
}

.slick-prev::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDE5TDggMTJMMTUgNSIgc3Ryb2tlPSIjMDA2NkZGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") !important;
  color: #0066ff !important;
  font-size: 24px !important;
}

.slick-next::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgNUwxNiAxMkw5IDE5IiBzdHJva2U9IiMwMDY2RkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") !important;
  color: #0066ff !important;
  font-size: 24px !important;
}

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.slick-next {
  top: 103.5% !important;
  left: 56% !important;
  z-index: 100;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 1024px) {
    left: 59% !important;
  }

  @media (max-width: 768px) {
    display: none !important;
  }
}

.slick-prev {
  top: 103.5% !important;
  left: 42% !important;
  z-index: 100;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 1024px) {
    left: 39% !important;
  }

  @media (max-width: 768px) {
    display: none !important;
  }
}
